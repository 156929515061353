import React from 'react'

import img from '../images/Web-developer.svg'

const AboutMe = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h2 className="text-3xl  text-blue-900 font-bold">
              Hello! A Bit About Me:
            </h2>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Hello, my name is Bryan Guillen. I'm a software developer lucky
                enough to have accumulated near 10 years of experience. My
                purpose is to serve smaller businesses and nonprofits.
              </p>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                A website is a mechanism to help you reach your goals, whatever
                that may be for you.
              </p>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Be it more donations, more traffic, better systems or something
                else, I aim to help you solve those problems for your
                organization.
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={img} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutMe
