import React from 'react'

import imgConsultation from '../images/consultation.svg'

const Intro = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="intro">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right"
              src={imgConsultation}
            />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h2 className="text-3xl  text-blue-900 font-bold">
              Wait! This is for You Only If:
            </h2>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                1. You are the decision maker in a nonprofit.
              </p>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                2. You have a feeling that a new website, or update to an
                existing website, could potentially support you in reaching your
                goals, whatever they may be.
              </p>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                3. You want peace of mind during this process, by working with
                someone who has been lucky to accumulate near 10 years of
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Intro
