import React from 'react'

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section>
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          Offering
        </h2>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <h3
                className="text-3xl  text-blue-900 
                            font-bold"
              >
                Results
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  I focus on result driven design; your website needs to get you
                  results, otherwise, it's useless. This will be our primary
                  focus: your needs and the results you want.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <h3 className="text-3xl  text-blue-900 font-bold">
                Professionalism
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  I am not perfect, but with the experience I've been fortunate
                  enough to accumulate, you can rest knowing that your idea is
                  in great hands.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <h3
                className="text-3xl  text-blue-900 
                            font-bold"
              >
                Personalization
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  No two projects have the same exact goals. You communicate
                  what you're looking to solve for, and I'll help craft a unique
                  solution tailored to your needs.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <h3 className="text-3xl  text-blue-900 font-bold">
                End to End Support
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  If you want, I'll manage EVERYTHING for you, regarding
                  websites. From getting it live to other support you may need
                  or want.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Services
