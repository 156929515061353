import React from 'react'
import Hero from '../components/Hero'
import Intro from '../components/Intro'
import AboutMe from '../components/AboutMe'
import Services from '../components/Services'
import Contact from '../components/Contact'

const Home = () => {
  return (
    <>
      <Hero />
      <Intro />
      <AboutMe />
      <Services />
      <Contact />
    </>
  )
}

export default Home
