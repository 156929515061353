import React, { useState } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
  const [firstName, setFirstName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')
  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [securityCheckboxChecked, setSecurityCheckboxChecked] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault()

    if (securityCheckboxChecked) {
      return setSuccessfulSubmission(true)
    }

    if (!firstName || !email || !phone || !organizationName) {
      return setError('Please ensure all fields are filled out')
    }

    setError('')

    document.getElementById('submitBtn').disabled = true
    document.getElementById('submitBtn').innerHTML = 'Loading...'

    // Send
    emailjs
      .send(
        'service_7ox2whe',
        'template_72f7qu4',
        {
          user_name: firstName,
          user_email: email,
          company: organizationName,
          phone,
        },
        'taHvVhwx28BQxvMeQ',
      )
      .then(() => {
        setSuccessfulSubmission(true)
      })
      .catch((error) => {
        console.log(error)
        setError(
          'Oops! Looks like something went wrong. Feel free to try again.',
        )
      })
      .finally(() => {
        document.getElementById('submitBtn').disabled = false
        document.getElementById('submitBtn').innerHTML = 'Send Message'
      })
  }
  return (
    <>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 "
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <form onSubmit={sendEmail}>
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl relative">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                  Let's Get In Touch
                </h1>
              </div>
              <p>
                Submit your info, so that we can get in touch and talk about if
                there's anything I can do for you.
              </p>
              {error ? (
                <p style={{ color: 'red' }}>
                  Please ensure all fields are filled out
                </p>
              ) : null}
              {successfulSubmission ? (
                <p style={{ color: 'green' }}>
                  Your submission has been successful. We'll be in touch within
                  the next few hours.
                </p>
              ) : null}
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <div>
                  <input
                    name="first_name"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="First Name*"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>

                <div>
                  <input
                    name="company"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Organization name*"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                  />
                </div>

                <div>
                  <input
                    name="email"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="email"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div>
                  <input
                    name="phone_number"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="number"
                    placeholder="Phone*"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="absolute -top-0 left-0 h-0 w-0 overflow-hidden">
                <label htmlFor="services-conditions">
                  Do you agree to the terms and services of using our
                  conditions?
                </label>
                <input
                  type="checkbox"
                  checked={securityCheckboxChecked}
                  id="services-conditions"
                  name="services-conditions"
                  onChange={() =>
                    setSecurityCheckboxChecked(
                      (previousState) => !previousState,
                    )
                  }
                />
              </div>
              <div className="absolute -top-0 left-0 h-1 w-1 z-10"></div>
              <div className="my-2 w-1/2 lg:w-2/4">
                <button
                  type="submit"
                  id="submitBtn"
                  className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Contact
